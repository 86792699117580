import React from "react";
import "@ojp/ojp-elements/dist/ojp-elements/ojp-elements.css";
import '../scss/main.scss';
import withLocation from "../components/withLocation";
import App from "../components/App";
import {Helmet} from "react-helmet";
import Mural from "../components/Mural";
import AnalyticsRepository from "../repositories/AnalyticsRepository";

class IndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <script type="text/javascript">
                        {AnalyticsRepository.getAnalyticsBootstrap()}
                    </script>
                    <script src="https://www.adobe.com/marketingtech/main.min.js"></script>
                </Helmet>
                <App  />
            </React.Fragment>
            );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
}

export default withLocation(IndexPage);